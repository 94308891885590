<template>
  <div v-if="$i18n.locale == 'srb'" :class="$vuetify.breakpoint.lgAndUp ? 'px-10' : ''">
    <v-img src="@/assets/DS620/specif_srb.jpg"></v-img>
  </div>
  <div v-else>
    <v-img src="@/assets/DS620/specif_eng.jpg"></v-img>
  </div>
  <!-- Old version -->
  <!-- <div>
    <h3>PRODUCT NAME</h3>
    <p>DP-DS620</p>

    <h3>PRINT TECHNOLOGY</h3>
    <p>Dye sublimation</p>

    <h3>RESOLUTION (DPI)</h3>
    <p>
      300 x 300 (High speed mode) <br />
      300 x 600 (High quality mode)
    </p>

    <h3>DIMENSIONS</h3>
    <p>
      Width 275mm <br />
      Depth 366mm <br />
      Height 170mm
    </p>

    <h3>WEIGHT</h3>
    <p>12kg</p>

    <h3>PAPER FEEDING</h3>
    <p>Roll paper (coreless)</p>

    <h3>FINISHING OPTIONS</h3>
    <p>Glossy, matte</p>

    <h3>MEDIA LOADING</h3>
    <p>Front loading</p>

    <h3>PRINT SPEED</h3>
    <p>
      300x300dpi <br />
      glossy <br />
      <br />
      4x6 inch <br />
      5x7 inch <br />
      6x8 inch <br />
      6x9 inch <br />
      <br />
      10x15cm <br />
      13x18cm <br />
      15x20cm <br />
      15x23cm <br />
      <br />
      8.4sec* <br />
      tbd <br /> 
      15.6sec <br />
      tbd <br />
    </p>

    <h3>ESTIMATED PROCESSING CAPACITY</h3>
    <p>
      (128 grey pattern) <br />
      <br />
      400 prints per hour (pph) <br />
    </p>

    <h3>PRINT AREA </h3>
    <p>
      2x6 inch <br />
      3.5x5 inch <br />
      4x6 inch <br />
      5x7 inch <br />
      6x8 inch <br />
      6x9 inch <br />
 <br />
      5x15cm <br />
      9x13cm <br />
      10x15cm <br />
      13x18cm <br />
      15x20cm <br />
      15x23cm <br />
 <br />
      51x152mm  <br />
      89x127mm <br />
      101x152mm <br />
      127x178mm <br />
      152x203mm <br />
      152x229mm <br />
    </p>

    <h3>MEDIA</h3>
    <p>5x7 inch <br />
      6x8 inch <br />
      6x9 inch <br />
 <br />
      13x18cm <br />
      15x20cm <br />
      15x23cm <br />
 <br />
      230 prints per roll <br />
      200 prints per roll <br />
      180 prints per roll <br />
    </p>

    <h3>POWER CONSUMPTION</h3>
    <p>
      Printing Standby (&lt;0.5W) <br />
      <br />
      1.6A (240V, 50/60Hz) <br />
      0.14A (240V, 50/60Hz) <br />
      <br />
      3.9A (100V, 50/60Hz) <br />
      0.24A (100V, 50/60Hz) <br />
      <br />
      PRINTER DRIVERS <br />
      <br />
      Windows Vista, Win 7, Win 8, Win XP <br />
      Mac OS X 10.9.5, 10.10 and 10.11 <br />
    </p>

    <h3>INTERFACE</h3>
    <p>USB2.0</p>

    <h3>BUFFER MEMORY</h3>
    <p>64MB</p>

    <h3>RIBBON REWIND FUNCTION</h3>
    <p>Yes (Europe)</p>

    <h3>POWER SUPPLY</h3>
    <p>
      Front <br />
        <br />
      * When you set the paper size setting to (6x4)xN and send more than two images to the printer.<br />
<br />
      Windows Vista, Windows 7, Windows 8 and Windows XP are trademarks or registered trademarks of Microsoft, Inc. All other trademarks are property of their respective owners.<br />
    </p>
  </div> -->
</template>

<script>
export default {

}
</script>

<style scoped>
p{
  margin: 18px 0;
  font-size: 1.2em
}
h4{
  font-size: 1.2em;
  padding-top: 15px
}
</style>